<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      ref="dataView"
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
    >
      <!-- Optional: Add custom column rendering if needed -->
    </DataViewWrapper>
  </div>
</template>

<script>
import models from '@/models'
import DataViewWrapper from '@/components/DataView/DataViewWrapper.vue'

export default {
  name: 'Products',
  components: {
    DataViewWrapper
  },
  data() {
    return {
      model: models.salesAdministrative.products,
      filters: [
        'Product Name Full',
        'Category', 
        'Base/Finished Product'
      ],
      tableColumns: [
        'ID',
        'Notes',
        'Product Name Full',
        'Category',
        'Base/Finished Product',
        'Bottle Size',
        'ABV',
        'Product Type',
        'Bottle Height',
        'Bottle Width',
        'Bottle Weight',
        'Case Size',
        'Cases per Layer',
        'Layers per Pallet',
        'Case Dimensions',
        'Case Height',
        'Case Weight',
        'GS1 UPC',
        'SCC Code',
        'NABCA Code',
        'TTB ID',
        'Schedule B',
        'CN Codes EU',
        'Unit Price',
        'Discontinued'
      ]
    }
  },
  mounted () {
    console.log('this.model', this.model)
  },
}
</script>
